<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="Email or username" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">Email or Username</label>
          <input type="text" :class="'form-control mb-0 placeholderpadding' + (errors.length > 0 ? ' is-invalid' : '')"
            id="emailInput" aria-describedby="emailHelp" v-model="user.email" placeholder="Enter email or username"
            required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <div class="position-relative">
            <input :type="showPassword ? 'text' : 'password'"
              :class="'form-control mb-0 placeholderpadding' + (errors.length > 0 ? ' is-invalid' : '')"
              id="passwordInput" v-model="user.password" placeholder="Password" required>
            <span class="password-toggle-icon" @click="togglePasswordVisibility">
              <i :class="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i>
            </span>
            <div class="invalid-feedback">
              <span>Password is required</span>
            </div>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <router-link to="/auth/forgot-password" class="">
          Forgot password?
        </router-link>
        <button type="submit" class="btn btn-primary float-right">Sign in</button>
      </div>

    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '../../../../event-bus';
// import Vue from 'vue'

export default {
  name: 'SignIn1Form',
  props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: ''
    },
    showPassword: false
  }),
  mounted() {
    this.user.email = this.$props.email
    this.user.password = this.$props.password
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    onSubmit() {
      this.axios
        .post('/login', {
          user: this.user.email,
          password: this.user.password,
        })
        .then(function (response) {

          localStorage.setItem('targetUpdated', response.data.data.target);

          localStorage.setItem('all_leads', 'false');
          localStorage.setItem('all_sales', 'false');
          localStorage.setItem('rc_widget', 'false');

          localStorage.setItem(
            process.env.VUE_APP_TOKEN_NAME,
            response.data.data.token,
          )
          const user = JSON.parse(atob(response.data.data.user));
          localStorage.setItem(
            'user',
            response.data.data.user,
          )
          localStorage.setItem(
            'menu',
            response.data.data.menu,
          )
          localStorage.setItem(
            'slugs',
            response.data.data.slugs,
          )
          localStorage.setItem(
            'notifications',
            JSON.stringify(response.data.data.notifications),
          )
          localStorage.setItem('call_queue_presence', user.call_queue_presence);


          const urlParams = new URLSearchParams(window.location.search);
          const rdr = urlParams.get('rdr');
          let newHref = "/lead"
          if (rdr) {
            newHref = rdr;
          }
          setTimeout(function () { window.location.href = newHref }, 3000);
        })
        .catch(function (error) {
        })
    },

  }
}
</script>

<style scoped>
.position-relative {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.input-group .form-control {
  padding-right: 2.5rem;
  /* Make space for the icon */
}
</style>
